import React from "react"
import { Link } from "gatsby"
import Image from "../../components/image"
import Layout from "../../components/layout"
// import SEO from "../components/seo"
import "../../assets/sass/snowplowing.sass"

const SnowPlowing = () => (
    <div class="info-page snow-plowing">

        <div>
            <h1>Snow Plowing and Salting</h1>
        </div>
        
        <Image filename="snow-shovel.jpeg"/>

        <div class="info-container">

            <div class="discription">
                <p>
                    Kevin's Landscaping team of snow removal professionals will help get you through the harsh Chicago winters here in Illinois. We can help clear your driveways, sidewalks, and parking lots after even the heaviest snowfall. 
                    We're reliable all year round, schedule routine winter property maintenance, our snow removal proffessinals will be there. Our salting service will increase the safety and functionality of your home. Contact us for more information.
                </p>
            </div>

        </div>

    </div>
)

export default SnowPlowing
